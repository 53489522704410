import React, { useState, useEffect } from 'react';
import { mainCategories } from '../data/jobData';

const CostCalculator = () => {
  const [teamSize, setTeamSize] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('Virtual Assistance');
  const [jobType, setJobType] = useState('Virtual Assistant');
  const [experience, setExperience] = useState('0-2 years');
  const [isAnnualContract, setIsAnnualContract] = useState(true);
  const [workLocation, setWorkLocation] = useState('Work From Home');

  const handleTeamSizeChange = (event) => {
    setTeamSize(parseInt(event.target.value));
  };

  const handleJobTypeChange = (type) => {
    setJobType(type);
  };

  const handleCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);
    // Set the job type to the first job type in the selected category
    const firstJobType = mainCategories.find(category => category.name === categoryName)?.jobTypes[0]?.name;
    if (firstJobType) {
      setJobType(firstJobType);
    }
  };

  const handleExperienceChange = (value) => {
    setExperience(value);
  };

  const handleContractLengthChange = () => {
    setIsAnnualContract(!isAnnualContract);
  };

  const handleWorkLocationChange = (location) => {
    setWorkLocation(location);
  };

  const calculateCost = () => {
    let baseCost = 0;

    // Find the selected job type in the mainCategories array and get its cost
    const selectedJobType = mainCategories
      .flatMap(category => category.jobTypes)
      .find(job => job.name === jobType);

    if (selectedJobType) {
      baseCost = selectedJobType.cost;
    }

    // Additional cost based on experience
    let experienceCost = 0;
    switch (experience) {
      case '3-5 years':
        experienceCost = 200;
        break;
      case '6-10 years':
        experienceCost = 400;
        break;
      case '10+ years':
        experienceCost = 600;
        break;
      default:
        experienceCost = 0;
        break;
    }

    const additionalCost = isAnnualContract ? 0 : 500;
    const locationCost = workLocation === 'Work From Office' ? 200 : 0;

    return (baseCost + experienceCost + additionalCost + locationCost) * teamSize;
  };

  const calculateSavings = () => {
    const bpoTeamCost = calculateCost(); // Get the BPO team cost using your existing calculateCost function
    const savingsPercentage = 70; // The savings percentage (Y)
    const savings = bpoTeamCost * (1 / (1 - savingsPercentage / 100) - 1); // Calculate the savings (Z)
    const monthlySavings = savings.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    const annualSavings = (savings * 12).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return { monthlySavings, annualSavings };
  };


  useEffect(() => {
    // Fetch initial data or perform other actions here
  }, []);

  const getJobTitle = () => {
    return teamSize > 1 ? jobType + 's' : jobType;
  };

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:py-32">
      <h2 className="text-base font-semibold leading-7 text-indigo-600">Monthly Cost Calculator</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">BPO team</p>
      <p className="mt-6 text-lg leading-8 text-gray-600 lg:max-w-3xl">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
        iste dolor cupiditate blanditiis ratione.
      </p>

      <hr className="my-12"></hr>

      <div className="lg:flex lg:gap-x-0">
        <div className="lg:w-2/3">
          <h3 className="text-xl font-bold mb-8">Job type</h3>
          <div className="flex flex-wrap gap-y-1 mb-4">
          {mainCategories.map((category, index) => (
            <div key={index} className="mb-1.5">
              <button
                className={`text-base text-sm mr-3 py-1.5 px-4 rounded-full tracking-normal dark:bg-indigo-100 bg-indigo-50 text-indigo-900 hover:opacity-100 font-semibold ${selectedCategory === category.name ? 'bg-indigo-700 dark:bg-indigo-700 text-white dark:text-white' : 'opacity-100'}`}
                onClick={() => handleCategoryChange(category.name)}
              >
                <span className="mr-2">{category.emoji}</span> {category.name}
              </button>
            </div>
          ))}
        </div>

        <div className="border border-gray-200 rounded-lg bg-indigo-25 p-0 mt-8">
          <div className="flex items-center justify-start px-4 py-4 mb-2">
            <h3 className="text-sm font-medium text-gray-900">
              Select <span className="font-bold">{selectedCategory}</span> specialization
            </h3>
          </div>
          <div className="flex flex-wrap gap-y-1 mb-4 mx-4">
            {mainCategories
              .filter((category) => category.name === selectedCategory)
              .map((category) =>
                category.jobTypes.map((job, index) => (
                  <div key={index} className="mb-2">
                    <button
                      className={`mr-2 py-1.5 px-4 text-sm rounded-full bg-grey-100 dark:bg-grey-100 border border-grey-700 text-grey-700 font-medium ${jobType === job.name ? 'bg-indigo-700 text-white' : ''}`}
                      onClick={() => handleJobTypeChange(job.name)}
                    >
                      {job.name}
                    </button>
                  </div>
                ))
              )}
          </div>
        </div>

     

          {/* Other sections of the component... */}
          <hr className="my-12"></hr>
          <div className="flex mt-2 justify-between items-center">
            <h3 className="text-xl font-semibold mb-8">Team Size</h3> 
            <div className="flex justify-start items-center text-2xl font-semibold rounded-full mb-8">

            <button className="text-sm mr-0 py-0.5 px-4 rounded-full tracking-normal dark:bg-gray-100 bg-gray-100 text-indigo-900 hover:opacity-100 font-semibold">
                <span className="mr-0 text-xl">🧑🏽‍💼</span><span class="text-gray-900 text-xl font-bold rounded-full ml-1.5">{teamSize}</span>
            </button>

            </div>
         </div>
         
         <div className="relative mb-6 py-4">
        <label htmlFor="team-size-range-input" className="sr-only">Team Size range</label>
        <input
          id="team-size-range-input"
          type="range"
          value={teamSize}
          min="1"
          max="50"
          onChange={handleTeamSizeChange}
          className="w-full h-3 bg-gray-100 rounded-lg appearance-none cursor-pointer dark:bg-gray-300"
        />
        </div>

          <hr className="my-12"></hr>

          <h3 className="text-xl font-semibold mb-8">Professional Experience</h3>
          <div className="flex flex-wrap mb-4">
            {['0-2 years', '3-5 years', '6-10 years', '10+ years'].map((exp, index) => (
              <div key={index} className="w-1/2 pr-2 mb-2">
                <button
                  className={`w-full text-sm bg-gray-100 text-indigo-700 font-bold py-2 px-4 rounded ${experience === exp ? 'bg-indigo-700 text-white' : ''}`}
                  onClick={() => handleExperienceChange(exp)}
                >
                  {exp}
                </button>
              </div>
            ))}
          </div>

          <hr className="m-12"></hr>

          <h3 className="text-xl font-semibold mb-8">Contract</h3>
          <div className="mb-4">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={!isAnnualContract}
                onChange={handleContractLengthChange}
              />
              <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-indigo-300 dark:peer-focus:ring-indigo-800 rounded-full peer dark:bg-indigo-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-indigo-600 peer-checked:bg-indigo-600"></div>
              <span className="ms-3 text-lg font-medium text-gray-900 dark:text-gray-900">
                {!isAnnualContract ? "Monthly Contract" : "Annual Contract"}
              </span>
            </label>
          </div>

          <hr className="m-12"></hr>

          <h3 className="text-xl font-semibold mb-8">Work Location</h3>
              
          <div className="grid grid-cols-2 gap-4">
            <div>
              <button
                className={`w-full text-sm bg-gray-100 text-indigo-700 font-bold py-2 px-4 rounded flex items-center justify-center ${workLocation === 'Work From Home' ? 'bg-indigo-700 text-white' : ''}`}
                onClick={() => handleWorkLocationChange('Work From Home')}
              >
                🏠 Work From Home
              </button>
            </div>
            <div>
              <button
                className={`w-full text-sm bg-gray-100 text-indigo-700 font-bold py-2 px-4 rounded flex items-center justify-center ${workLocation === 'Work From Office' ? 'bg-indigo-700 text-white' : ''}`}
                onClick={() => handleWorkLocationChange('Work From Office')}
              >
                🏢 Work From Office
              </button>
            </div>
          </div>

        </div>

        <hr className="m-12"></hr>

        <div className="lg:w-1/3 lg:pt-4">
          <div className="lg:top-24 lg:sticky"> 
         <div className="mb-4 p-8 pb-12 bg-gray-100 rounded-lg">
            <p className="text-sm font-semibold leading-5 text-indigo-600">BPO team Cost</p>
            <p className="text-2xl font-semibold">
              {calculateCost().toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              /month
            </p>       
            <hr className="my-4"></hr>
            <p className="text-md leading-6 text-gray-900">
              A team of <span className="text-indigo-700 font-semibold">{teamSize} {getJobTitle()}</span> with <span className="text-indigo-700 font-semibold">{experience} </span> experience,
              working on <span className="text-indigo-700 font-semibold">{isAnnualContract ? 'an Annual' : 'a Monthly'} Contract</span> working from <span className="text-indigo-700 font-semibold">{workLocation === 'Work From Office' ? 'an Office' : 'Home'}</span> 
            </p>
            <hr className="my-8"></hr>
            <a href="/" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Get in contact with Sales
            </a> 
            <hr className="my-8"></hr>
            <p className="text-sm font-semibold leading-5 text-indigo-600">
                {isAnnualContract ? "Annual" : "Monthly"} Savings
              </p>
              <p className="text-lg font-semibold">
                {isAnnualContract ? calculateSavings().annualSavings : calculateSavings().monthlySavings}
              </p>  
              <p className="text-xs leading-6 text-gray-600">* Compared to US cost</p> 
          </div>

          {teamSize === 50 && (
            <p className="text-sm leading-6 text-gray-600">
                <hr className="my-8"></hr>
                Need a larger team? We can help you, please contact <a href="#" className="text-indigo-600">Enterprise Sales Department</a>
            </p>
          )}
          </div>         



        </div>
      </div>
    </div>
  );
};

export default CostCalculator;
