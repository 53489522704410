export const mainCategories = [
    {
      name: 'Virtual Assistance',
      emoji: '💼',
      jobTypes: [
        { name: 'Virtual Assistant', cost: 899 },
        { name: 'Executive Assistant', cost: 999 },
        { name: 'Admin Assistant', cost: 1099 },
      ]
    },
    {
      name: 'Customer Service',
      emoji: '📞',
      jobTypes: [
        { name: 'Customer Service Representative', cost: 899 },
        { name: 'Technical Support Specialist', cost: 999 },
      ]
    },
    {
      name: 'Marketing',
      emoji: '📈',
      jobTypes: [
        { name: 'Outbound Marketing Specialist', cost: 899 },
        { name: 'Inbound Marketing Specialist', cost: 999 },
        { name: 'Social Media Specialist', cost: 1099 },
        { name: 'Content Writer', cost: 1099 },
        { name: 'Copywriter', cost: 999 },
        { name: 'SEO Specialist', cost: 1099 },
        { name: 'SEM Specialist', cost: 1099 },
      ]
    },
    {
      name: 'Data Entry',
      emoji: '📝',
      jobTypes: [
        { name: 'Data Entry Specialist', cost: 899 },
        { name: 'Data Processing Specialist', cost: 999 },
        { name: 'Data Analyst', cost: 1099 },
        { name: 'Data Quality Analyst', cost: 1099 },
        { name: 'Transcriptionist', cost: 999 },
        { name: 'Medical Transcriptionist', cost: 1099 },
      ]
    },
    {
      name: 'Finance & Accounting',
      emoji: '💰',
      jobTypes: [
        { name: 'Bookkeeper', cost: 899 },
        { name: 'Accountant', cost: 999 },
        { name: 'Accounts Payable Specialist', cost: 1099 },
        { name: 'Accounts Receivable Specialist', cost: 1099 },
        { name: 'Financial Analyst', cost: 999 },
      ]
    },
    {
      name: 'Human Resources',
      emoji: '👥',
      jobTypes: [
        { name: 'Recruitment Specialist', cost: 899 },
        { name: 'Talent Acquisition Specialist', cost: 999 },
        { name: 'HR Administrator', cost: 1099 },
        { name: 'HR Coordinator', cost: 1099 },
        { name: 'Training Coordinator', cost: 999 },
        { name: 'Learning and Development Specialist', cost: 1099 },
      ]
    },
    {
      name: 'Social Media',
      emoji: '📱',
      jobTypes: [
        { name: 'Social Media Manager', cost: 1199 },
        { name: 'Social Media Analyst', cost: 1099 },
        { name: 'Content Creator', cost: 999 },
        { name: 'Community Manager', cost: 999 },
        { name: 'Influencer Manager', cost: 1099 },
        { name: 'Social Media Moderator', cost: 999 }
      ]
    },
    {
      name: 'IT Development',
      emoji: '💻',
      jobTypes: [
        { name: 'Frontend Developer', cost: 1299 },
        { name: 'Backend Developer', cost: 1299 },
        { name: 'Full Stack Developer', cost: 1399 },
        { name: 'Software Engineer', cost: 1399 },
        { name: 'UI/UX Designer', cost: 1299 },
        { name: 'Quality Assurance Engineer', cost: 1299 }
      ]
    },
    {
      name: 'Content Creation',
      emoji: '✍️',
      jobTypes: [
        { name: 'Content Writer', cost: 999 },
        { name: 'Copywriter', cost: 999 },
        { name: 'Editor', cost: 1099 },
        { name: 'Proofreader', cost: 999 },
        { name: 'Translator', cost: 1099 },
        { name: 'Scriptwriter', cost: 999 }
      ]
    },
    {
      name: 'Sales',
      emoji: '🛒',
      jobTypes: [
        { name: 'Sales Manager', cost: 1299 },
        { name: 'Sales Executive', cost: 1199 },
        { name: 'Business Development Representative', cost: 1199 },
        { name: 'Account Manager', cost: 1299 },
        { name: 'Lead Generation Specialist', cost: 1199 },
        { name: 'Sales Consultant', cost: 1199 }
      ]
    }
  ];
  