import React, { useState } from 'react';
import Logos from '../components/Logos';
import Content from '../components/Content';
import Testimonials from '../components/Testimonials';
import Pricing from '../components/Pricing';
import People from '../components/People';
import Benefits from '../components/Benefits';
import Resume from '../components/Resume';
import Footer from '../components/FooterSmall';
import Header from '../headerDark';
import Calculator from '../components/CalculatorAdvanced';

function About() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  }; 

  return (
    <div className="About">
      <Header
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          closeMobileMenu={closeMobileMenu}
        />
      <Calculator />  
      <Benefits />  
      <Logos />
      <Testimonials />  
      <Resume />
      <Content />
      <People />
      <Pricing />
      <Footer />
    </div>
  );
}

export default About;