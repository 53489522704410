
export default function Example() {
  return (
   <div className='overflow-x-hidden'>

    <div className="object-cover absolute h-screen xl:max-h-[808px] w-screen -z-10 top-0 left-0 bg-black opacity-83"></div>
    {/* background */}
    <video src={"https://static.videezy.com/system/resources/previews/000/048/542/original/a-OperatorManWorkAtNightWithTeam.mp4"} 
      loop 
      autoPlay 
      muted 
      playsInline
      className='object-cover absolute h-screen xl:max-h-[864px] w-screen xl:max-w-[1536px] -z-10 top-0 left-1/2 transform -translate-x-1/2'>
    </video>
     {/* dark overlay */}
     <div className="object-cover absolute h-screen xl:max-h-[864px] w-screen xl:max-w-[1536px] -z-10 top-0 left-1/2 transform -translate-x-1/2 bg-black opacity-65">
      {/* ring overlay */}
     <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true">
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
     </div>
    {/* content */}
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 px-8 md:px-0">
       <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-5 py-1 text-sm leading-6 text-gray-50 ring-1 ring-gray-100/65 hover:ring-gray-100/40">
              Get your own BPO team, starting from{' '}
            <a href="https://tailwindui.com/components/marketing/sections/heroes" className="font-semibold text-indigo-200">
              <span className="absolute inset-0" aria-hidden="true" />
                $ 1.199 / month <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <div className="text-center">
           <h1 className="text-3xl font-bold tracking-tight font-sans text-gray-50 sm:text-5xl">
                A BPO experience like no others
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-100">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
                fugiat veniam occaecat fugiat aliqua.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://tailwindui.com/components/marketing/sections/heroes"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a href="https://tailwindui.com/components/marketing/sections/heroes" className="text-sm font-semibold leading-6 text-gray-100">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>    
         
        </div>
    </div>
 
  )
}