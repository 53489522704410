import React, { useState } from 'react';
import Logos from '../components/Logos';
import Content from '../components/Content';
import Testimonials from '../components/Testimonials';
import Stats from '../components/Stats';
import Pricing from '../components/Pricing';
import People from '../components/People';
import Benefits from '../components/Benefits';
import Features from '../components/Features';
import Hero from '../components/Hero';
import Footer from '../components/FooterSmall';
import Header from '../headerDark';

function Home() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  }; 

  return (
    <div className="Home">
      <Header
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          closeMobileMenu={closeMobileMenu}
        />
      <Hero />
      <Logos />
      <Features />
      <Stats />
      <Content />
      <Testimonials />
      <Benefits />
      <People />
      <Pricing />
      <Footer />
    </div>
  );
}

export default Home;
