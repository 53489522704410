import React, { useState } from 'react';
import Herovideo from '../components/Herovideo';
import Logos from '../components/Logos';
import Content from '../components/Contentvideo';
import Testimonials from '../components/Testimonials';
import Stats from '../components/Stats';
import Pricing from '../components/Pricing';
import People from '../components/People';
import Benefits from '../components/Benefits';
import Features from '../components/Features';
import Footer from '../components/FooterSmall';
import Header from '../header';

function Product() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  }; 

  return (
    <div className="Product">
      <Header
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          closeMobileMenu={closeMobileMenu}
        />
      <Herovideo />
      <Stats />
      <Features />
      <Logos />
      <Testimonials />
      <Content />
      <People />
      <Benefits />
      <Pricing />
      <Footer />
    </div>
  );
}

export default Product;