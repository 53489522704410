export default function Example() {
    return (
    <footer class="pt-20 sm:pt-32 text-center pb-16">

        <div class="flex flex-col items-center justify-center">
    
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6_93)">
              <rect width="32" height="32" fill="white"/>
              <path d="M14.9973 28.9614C17.2934 29.1422 19.5961 28.7072 21.668 27.7014C20.0861 26.0366 18.8579 24.0686 18.0573 21.9161C15.9329 22.0892 13.7951 21.9933 11.6947 21.6308C12.3543 24.2501 13.4725 26.7319 14.9973 28.9614ZM11.2667 19.5121C13.3026 19.9379 15.3868 20.0868 17.4627 19.9548C17.16 18.6854 17 17.3614 17 16.0001C17 14.6388 17.16 13.3148 17.4627 12.0454C15.3868 11.9164 13.3031 12.0653 11.2667 12.4881C10.9099 14.8158 10.9099 17.1843 11.2667 19.5121ZM9.17467 13.0294C8.94117 15.003 8.94117 16.9972 9.17467 18.9708C6.99123 18.2925 4.92163 17.2911 3.03466 16.0001C4.92161 14.709 6.99122 13.7077 9.17467 13.0294ZM11.6933 10.3694C13.7944 10.0049 15.9334 9.90906 18.0587 10.0841C18.859 7.93113 20.0873 5.96264 21.6693 4.29742C19.5969 3.29184 17.2937 2.85735 14.9973 3.03875C13.4725 5.26827 12.353 7.75012 11.6933 10.3694ZM12.3307 28.4748C10.0494 27.8016 7.99615 26.5163 6.39377 24.7586C4.7914 23.0008 3.70113 20.8377 3.24133 18.5041C5.20632 19.6728 7.32317 20.5647 9.532 21.1548C10.0712 23.7274 11.0154 26.1982 12.3293 28.4748H12.3307ZM12.3307 3.52542C11.036 5.76809 10.08 8.23209 9.532 10.8454C7.32317 11.4354 5.20632 12.3274 3.24133 13.4961C3.70102 11.1626 4.79109 8.99961 6.39322 7.24188C7.99535 5.48414 10.0483 4.19882 12.3293 3.52542H12.3307ZM28.76 18.5041C28.1107 21.8077 26.2056 24.7308 23.4453 26.6588C22.0075 25.2356 20.8719 23.5368 20.1067 21.6641C23.1629 21.1595 26.0978 20.0878 28.76 18.5041ZM28.9653 16.0001C26.1225 17.946 22.8769 19.2245 19.4707 19.7401C19.164 18.5441 19 17.2908 19 16.0001C19 14.7094 19.1627 13.4561 19.4707 12.2601C22.8769 12.7757 26.1226 14.0542 28.9653 16.0001ZM28.76 13.4961C26.0978 11.9124 23.1629 10.8406 20.1067 10.3361C20.8719 8.46336 22.0075 6.7645 23.4453 5.34142C26.2052 7.26947 28.1111 10.1926 28.76 13.4961Z" fill="#4F46E5"/>
              </g>
              <defs>
              <clipPath id="clip0_6_93">
              <rect width="32" height="32" fill="white"/>
              </clipPath>
              </defs>
          </svg>
            <p class="mt-4 text-sm leading-6 text-slate-500">© 2024 bpo.team - All rights reserved.</p>
            
        </div>
    
    </footer>
    )
  }